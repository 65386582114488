import React from "react"
import { graphql } from "gatsby"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ExperienceCentre = ({ data,location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content
  
  return (
    <Layout>
      <Nav urlParam={url_param}/>
      <div className="masthead relative">
        <div className="masthead hidden lg:block">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_desktop
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        <div className="masthead block lg:hidden">
          <GatsbyImage
            image={getImage(
              data.allMdx.edges[0].node.frontmatter.masthead_mobile
            )}
            alt={data.allMdx.edges[0].node.frontmatter.title}
          />
        </div>
        {/* <div className="relative md:absolute text-black md:text-white top-0 left-0 w-full h-full">
          <div className="container py-5 md:mt-36">
            <h1 className="font-light text-2xl md:text-4xl xl:text-5xl">
              {data.allMdx.edges[0].node.frontmatter.masthead_line1 !== ""}
              <span className="inline md:block">
                {data.allMdx.edges[0].node.frontmatter.masthead_line1}
                {data.allMdx.edges[0].node.frontmatter.masthead_line2 !==
                  "" && <span className="inline">&nbsp;</span>}
              </span>
              {data.allMdx.edges[0].node.frontmatter.masthead_line2 !== "" && (
                <span className="inline md:block md:mt-1">
                  {data.allMdx.edges[0].node.frontmatter.masthead_line2}
                </span>
              )}
            </h1>
          </div>
        </div> */}
      </div>
      <section className="section sectionFirst">
        <div className="container py-5">
          <div className="about-description">
            
          <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">Introducing the BMW Eurokars Experience Centre</h2>
            <h3 className="mb-3"><strong>Discover the Future of Automotive Retail with BMW Retail.Next</strong></h3>
            <p className="mb-5 lg:pr-5">
            Experience the evolution of automotive retail with BMW Retail.Next, an innovative concept designed to redefine your interaction with the BMW Group brands. Whether online or offline, we promise a holistic experience that combines cutting-edge design, digital technologies, and dedicated customer service to deliver a best-in-class premium experience.
            </p>

            <h2 className="text-xl md:text-3xl xl:text-4xl font-light uppercase mb-10">Explore the BMW Eurokars Experience Centre</h2>
            <h3 className="mb-3"><strong>Step into the Future of Showrooms</strong></h3>
            <p className="mb-5 lg:pr-5">
            The BMW Eurokars Experience Centre is a groundbreaking manifestation of the Retail.Next concept, spanning nearly 796m2 as the first showroom of its kind in Singapore. Immerse yourself in a stylish interior designed for comfort, featuring multi-functional spaces and the latest digital technologies seamlessly integrated to engage and inform our valued customers.
            </p>

            <h3 className="mb-3"><strong>Experience Luxury and Innovation</strong></h3>
            <p className="mb-5 lg:pr-5">
            Indulge in the warm, progressive, and inviting environment of the Eurokars Experience Centre. From integrated Welcome and Customer Consultation areas to Sales Lounges, 3D digital configuration tools, and exclusive spaces for vehicle handovers or presentations, every aspect is tailored to meet your desires and needs.
            </p>

            <h3 className="mb-3"><strong>Elevating Your Customer Journey</strong></h3>
            <p className="mb-5 lg:pr-5">Introducing Emotional Virtual Experience (EVE)<br />
            Enhance your vehicle selection process with cutting-edge digital tools. EVE, our Emotional Virtual Experience, is a 3D-rendering software that allows you to explore your configured BMW in almost photo-realistic quality. Visualize your dream car from every angle, both inside and out, and experience it in different environments day or night.
            </p>

            <h3 className="mb-3"><strong>Service Excellence Beyond Expectations</strong></h3>
            <p className="mb-5 lg:pr-5">Unparalleled Service Convenience<br />
            At the BMW Eurokars Experience Centre, service excellence is paramount. Benefit from eight state-of-the-art Mechanical and Electrical work bays equipped with the latest BMW-standardized tools. For electric vehicle owners, we offer six dedicated charging points in the customer parking area to support your sustainable journey.
            </p>

            <h3 className="mb-3"><strong>Plan Your Visit to the BMW Eurokars Experience Centre</strong></h3>
            <p className="mb-5 lg:pr-5">Immerse Yourself in Luxury<br />
            Plan your visit to the BMW Eurokars Experience Centre and immerse yourself in luxury, innovation, and the epitome of a superior customer experience. Explore the Retail.Next Concept, experience digital innovation, and relax in our cosy, plush BMW lounge area. Your journey into the future of automotive retail starts here.
            </p>

            <h3 className="mb-3"><strong>Your unparalleled BMW experience awaits</strong></h3>
            <p className="mb-5 lg:pr-5">
            Eurokars Auto Pte Ltd. (EAPL), a subsidiary of the esteemed Eurokars Group, Singapore’s largest privately-owned automotive dealership, proudly represents the essence of BMW luxury as an authorised dealer. We are privileged to offer you an entire line-up of BMW vehicles, from the innovative electric BMW i Series to the exhilarating high-performance BMW M models.<br /><br />At BMW Eurokars Auto, our unwavering dedication is to enhance every aspect of your ownership journey. Discover the epitome of BMW luxury at your premier destination. With our “Book Now, Service Tomorrow” commitment, we ensure your BMW experience remains impeccable. <br /><br />
            Visit us at our BMW Eurokars Experience Centre located at 11 Kung Chong Road, BMW Eurokars Auto Showroom at 11 Leng Kee Road, and Drive the Difference with us today.
            </p>
            
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Retail Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Experience Centre</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Auto Showroom</span>
                 
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Leng Kee Road, Singapore 159091  
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  Monday &ndash; Saturday: 8:30am &ndash; 7pm <br />
                  Sunday & Public Holidays: 10am &ndash; 6pm 
                </div>
              </div>
            </div>
          </div>
          <div className="showroom-contact mt-8 md:mt-12">
            <h2 className="text-l md:text-2xl xl:text-3xl font-light uppercase mb-3">
              Service Locations
            </h2>
            <div className="showroom-contact-details">
              <div className="grid grid-cols-1 md:grid-cols-12 border-t md:border-0 border-neutral-200">
                <div className="md:col-span-3 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Location:
                </div>
                <div className="md:col-span-4 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Address:
                </div>
                <div className="md:col-span-5 py-3 border-t border-b border-neutral-200 font-bold hidden md:block">
                  Opening Hours:
                </div>

                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre </span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  11 Kung Chong Road, Singapore 159147 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
                <div className="md:col-span-3 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Location:
                  </span>
                  <span className="font-bold">BMW Eurokars Authorised Service Centre</span>
                  
                </div>
                <div className="md:col-span-4 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Address:
                  </span>
                  29 Leng Kee Road, Singapore 159099 
                </div>
                <div className="md:col-span-5 py-3 ">
                  <span className="font-bold block md:hidden mb-1">
                    Opening Hours:
                  </span>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                    Monday &ndash; Friday: 8am &ndash; 6pm <br />
                    Saturday: 8am &ndash; 12pm <br />
                    Sunday & Public Holidays: Closed
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer urlParam={url_param} />
    </Layout>
  )
}
export const query = graphql`
  query {
    allMdx(filter: { frontmatter: { page_id: { eq: "bmw_eurokars_experience_centre" } } }) {
      edges {
        node {
          frontmatter {
            slug
            page_id
            date
            title
            description
            masthead_line1
            masthead_line2
            masthead_desktop {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 300 }
                  placeholder: BLURRED
                  breakpoints: [1080, 1366, 1920]
                  outputPixelDensities: [1, 1.5, 2]
                )
              }
            }
            masthead_mobile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  blurredOptions: { width: 150 }
                  placeholder: BLURRED
                  breakpoints: [650]
                  outputPixelDensities: [2]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default ExperienceCentre

const seoDetails = {
  title: "Eurokars Auto | BMW Eurokars Experience Centre",
  description:
    "As an official dealer of BMW, Eurokars Auto offers the full range of BMW vehicles – from fully electric BMW i models to high-performance BMW M models.",
  keywords: "Eurokars, Eurokars Auto, BMW, BMW Eurokars Auto",
  image: "og-eka-about-us.jpg",
}

export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
